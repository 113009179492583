<template>
  <div class="yetJoinComponentes">
    <div class="title tc">
      {{ $t("yetJoinTip1")
      }}<!-- 还没有加入企业，请完善您的企业信息 -->
    </div>
    <div class="content">
      <div class="key">{{ $t("companyName") }}</div>
      <div class="value">
        <el-input
          v-model="company_name"
          :placeholder="$t('ccompanyPlaceholder')"
          readonly
        />
        <div class="mark" @click="openDialog"></div>
      </div>
      <div class="tc">
        <el-button
          type="primary"
          style="width: 360px"
          @click="saveCompanyInfo"
          :loading="saveLoading"
          >{{ $t("save") }}</el-button
        >
      </div>
    </div>
    <register ref="registerCompany" @confirmJoin="confirmJoin">
      <!-- <div slot="btnRight">查看公司</div> -->
    </register>
  </div>
</template>
<script>
import { priorFormat } from "~/basePlugins/filters";
import register from "~/baseComponents/registerCompany";
export default {
  components: { register },
  data() {
    return { company_name: "", saveLoading: false };
  },
  methods: {
    openDialog() {
      this.$refs.registerCompany.openPanel();
    },
    confirmJoin(result) {
      this.companyInfo = result.result;
      this.company_name = result.name;
    },
    async saveCompanyInfo() {
      if (!this.company_name) return;
      this.saveLoading = true;
      let _params = {
        user_id: this.USER_INFO.id,
      };
      let params = Object.assign(_params, this.companyInfo);
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_bindInfo",
          params
        );
        this.saveLoading = false;
        if (result.success) {
          this.$ssTip();
          this.$emit("bindCompany");
          this.getStatus();
          // if (this.companyInfo.id && this.companyInfo.status !== 0) {
          //   this.$emit("changeCompany", 1);
          // } else {
          //   this.$emit("changeCompany", 2);
          // }
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.saveLoading = false;
        this.$seTip();
      }
    },
    async getStatus() {
      try {
        let result = await this.$store.dispatch("baseStore/getCompanyStatus", {
          user_id: this.USER_INFO.id,
        });
        if (result.success) {
          let status = result.data.status;
          this.$emit("changeCompany", status);
        }
      } catch (e) {}
    },
  },
};
</script>
<style lang="less" scoped>
.yetJoinComponentes {
  padding: 0 12px;
  font-size: 15px;
  margin-top: 100px;
  .title {
    font-weight: bold;
  }
  .content {
    // padding-left: 30px;
    width: 360px;
    margin: 30px auto;
    .key {
    }
    .value {
      margin: 12px 0 30px;
      position: relative;
      min-height: 40px;
      .mark {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>