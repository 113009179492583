export const staffsizeList = [ //企业规模
    {
        value: 1,
        label_en: "1-50 people",
        label_zh: "1-50人"
    },
    {
        value: 2,
        label_en: "51-100 people",
        label_zh: "51-100人"
    },
    {
        value: 3,
        label_en: "101-200 people",
        label_zh: "101-200人"
    },
    {
        value: 4,
        label_en: "201-500 people",
        label_zh: "201-500人"
    },
    {
        value: 5,
        label_en: "501-1000 people",
        label_zh: "501-1000人"
    },
    {
        value: 6,
        label_en: "More than 1001 people",
        label_zh: "1001人及以上"
    }
]

export const levelRequire = [           //学历
    {
        value: 1,
        label_zh: '初中及以下'
    },
    {
        value: 2,
        label_zh: '中专/中技'
    },
    {
        value: 3,
        label_zh: '高中'
    },
    {
        value: 4,
        label_zh: '大专'
    },
    {
        value: 5,
        label_zh: '本科'
    },
    {
        value: 6,
        label_zh: '硕士'
    },
    {
        value: 7,
        label_zh: '博士'
    }
]

export const positionTypeList = [           //职位类型
  {
    value: 1,
    label_zh: '销售'
  },
  {
    value: 2,
    label_zh: '操作/报关/单证'
  },
  {
    value: 3,
    label_zh: '客服'
  },
  {
    value: 4,
    label_zh: '市场/商务'
  },
  {
    value: 5,
    label_zh: '仓储/运输/配送'
  },
  {
    value: 6,
    label_zh: '财务'
  },
  {
    value: 7,
    label_zh: '人力资源'
  },
  {
    value: 8,
    label_zh: '行政/后勤/文秘'
  },
  {
    value: 9,
    label_zh: '采购/外贸'
  },
  {
    value: 10,
    label_zh: '高级管理'
  },
  {
    value: 11,
    label_zh: '其他'
  }
]
export const wages = [
    {
      value: 1,
      label_en: '1k',
      label_zh: '1k',
    },
    {
      value: 2,
      label_en: '2k',
      label_zh: '2k',
    },
    {
      value: 3,
      label_en: '3k',
      label_zh: '3k',
    },
    {
      value: 4,
      label_en: '4k',
      label_zh: '4k',
    },
    {
      value: 5,
      label_en: '5k',
      label_zh: '5k',
    },
    {
      value: 6,
      label_en: '6k',
      label_zh: '6k',
    },
    {
      value: 7,
      label_en: '7k',
      label_zh: '7k',
    },
    {
      value: 8,
      label_en: '8k',
      label_zh: '8k',
    },
    {
      value: 9,
      label_en: '9k',
      label_zh: '9k',
    },
    {
      value: 10,
      label_en: '10k',
      label_zh: '10k',
    },
    {
      value: 11,
      label_en: '11k',
      label_zh: '11k',
    },
    {
      value: 12,
      label_en: '12k',
      label_zh: '12k',
    },
    {
      value: 13,
      label_en: '13k',
      label_zh: '13k',
    },
    {
      value: 14,
      label_en: '14k',
      label_zh: '14k',
    },
    {
      value: 15,
      label_en: '15k',
      label_zh: '15k',
    },
    {
      value: 16,
      label_en: '16k',
      label_zh: '16k',
    },
    {
      value: 17,
      label_en: '17k',
      label_zh: '17k',
    },
    {
      value: 18,
      label_en: '18k',
      label_zh: '18k',
    },
    {
      value: 19,
      label_en: '19k',
      label_zh: '19k',
    },
    {
      value: 20,
      label_en: '20k',
      label_zh: '20k',
    }
  ]

  export const schoolType = [
    {
      value:1,
      label_en: '全日制',
      label_zh: '全日制',
    },
    {
      value:2,
      label_en: '非全日制',
      label_zh: '非全日制',
    }
  ]
  export const jobType = [
    {
      value: 1,
      label_en: '全职',
      label_zh: '全职',
    },
    {
      value: 2,
      label_en: '兼职',
      label_zh: '兼职',
    }
  ]

export const experience=[
  {
    value:'1',
    label_zh: '不限'
  },
  {
    value:'2',
    label_zh: '在校/应届'
  },
  {
    value:'3',
    label_zh: '1年以内'
  },
  {
    value:'4',
    label_zh: '1-3年'
  },
  {
    value:'5',
    label_zh: '3-5年'
  },
  {
    value:'6',
    label_zh: '5-10年'
  },
  {
    value:'7',
    label_zh: '10年以上'
  }
]
