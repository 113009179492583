<template>
  <div class="content_info">
    <div class="explain">
      {{ $t("userbasicInfoMainTip") }} {{ $t("userbasicInfoSubTip") }}
    </div>
    <div class="fl contentLeft">
      <el-form
        label-width="120px"
        :model="edit_Company_Info"
        ref="edit_Company_Info"
        :hide-required-asterisk="true"
      >
        <div class="item clearfix">
          <div class="key">企业名称：</div>
          <div class="value">
            <p v-if="isExamine">{{ company_Info.name_zh | textFormat }}</p>
            <el-form-item v-else prop="name_zh">
              <el-input
                :disabled="!hasSavePower"
                v-model="edit_Company_Info.name_zh"
                class="input"
                size="small"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="item clearfix">
          <div class="itemInner clearfix left">
            <div class="key">国家：</div>
            <div class="value">
              <p v-if="isExamine">
                {{ company_Info.country | countryFormat("zh") }}
              </p>
              <el-form-item v-else prop="country">
                <CountrySelect
                  :disabled="!hasSavePower"
                  v-model="edit_Company_Info.country"
                  :locale="locale"
                  v-if="locale == 'zh' || PJID=='YMP'"
                  placeTitle
                  size="small"
                ></CountrySelect>
              </el-form-item>
            </div>
          </div>
          <div class="itemInner clearfix right">
            <div class="key">区域：</div>
            <div class="value">
              <p v-if="isExamine">
                {{ company_Info.region_zh | textFormat }}
              </p>
              <el-form-item v-else>
                <CityComplete
                  :disabled="!hasSavePower"
                  v-model="edit_Company_Info.region_zh"
                  size="medium"
                  locale="zh"
                  :countryId="edit_Company_Info.country"
                  placeTitle
                ></CityComplete>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="item clearfix" v-if="PJID !== 'YMP'">
          <div class="itemInner clearfix left">
            <div class="key">注册资金：</div>
            <div class="value">
              <p v-if="isExamine">
                {{ company_Info.registered_capital | textFormat }}
              </p>
              <el-form-item v-else prop="registered_capital">
                <el-input
                  :disabled="!hasSavePower"
                  v-model="edit_Company_Info.registered_capital"
                  size="small"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="itemInner clearfix right">
            <div class="key">企业规模：</div>
            <div class="value">
              <p v-if="isExamine">
                {{ company_Info.scale | staffsizeFormat("zh") }}
              </p>
              <el-form-item v-else prop="scale">
                <el-select
                  :disabled="!hasSavePower"
                  style="width: 100%"
                  filterable
                  v-model="edit_Company_Info.scale"
                  placeholder=" "
                  size="small"
                >
                  <el-option
                    v-for="item in staffsizeList"
                    :key="item.value"
                    :label="item.label_zh"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="item clearfix" v-if="PJID !== 'YMP'">
          <div class="itemInner clearfix left">
            <div class="key">注册年份：</div>
            <div class="value">
              <p v-if="isExamine">
                {{ company_Info.found_year | textFormat }}
              </p>
              <el-form-item v-else prop="found_year">
                <el-date-picker
                  :disabled="!hasSavePower"
                  size="small"
                  style="width: 100%"
                  v-model="edit_Company_Info.found_year"
                  type="year"
                  placeholder=" "
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </div>
          </div>
          <div class="itemInner clearfix right">
            <div class="key">企业官网：</div>
            <div class="value">
              <p v-if="isExamine">{{ company_Info.website | textFormat }}</p>
              <el-form-item v-else>
                <el-input
                  :disabled="!hasSavePower"
                  v-model="edit_Company_Info.website"
                  size="small"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="item clearfix">
          <div class="itemInner clearfix left">
            <div class="key">联系人：</div>
            <div class="value">
              <p v-if="isExamine">{{ company_Info.contacts | textFormat }}</p>
              <el-form-item v-else prop="contacts">
                <el-input
                  :disabled="!hasSavePower"
                  v-model="edit_Company_Info.contacts"
                  size="small"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="itemInner clearfix right">
            <div class="key">邮箱：</div>
            <div class="value">
              <p v-if="isExamine">{{ company_Info.email | textFormat }}</p>
              <el-form-item v-else prop="email">
                <el-input
                  :disabled="!hasSavePower"
                  v-model="edit_Company_Info.email"
                  size="small"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="item clearfix">
          <div class="itemInner clearfix left">
            <div class="key">联系电话：</div>
            <div class="value">
              <p v-if="isExamine">
                <span>{{
                  company_Info.telephone | phoneFormat(company_Info.area_code)
                }}</span>
              </p>
              <el-form-item v-else prop="telephone">
                <el-input
                  :disabled="!hasSavePower"
                  v-model="edit_Company_Info.telephone"
                  size="small"
                >
                  <el-select
                    :disabled="!hasSavePower"
                    style="width: 90px"
                    v-model="edit_Company_Info.area_code"
                    slot="prepend"
                    class="companyTelephoneList"
                    placeholder="请选择"
                    popper-class="registerAreaCodeList"
                    filterable
                    :filter-method="areaCodeFilter"
                  >
                    <el-option
                      v-for="(item, index) in areaCodeList"
                      :key="item.area_code + index + ''"
                      :value="item.area_code"
                      :title="item.country | areacodeTitleFormat('zh')"
                    >
                      <p class="area_code">{{ item.area_code }}</p>
                      <p class="country textOverflow">
                        {{ item.country | areacodeAddressFormat("zh") }}
                      </p>
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </div>
          </div>
          <div class="itemInner clearfix right">
            <div class="key">传真：</div>
            <div class="value">
              <p v-if="isExamine">{{ company_Info.fax | textFormat }}</p>
              <el-form-item v-else>
                <el-input
                  :disabled="!hasSavePower"
                  v-model="edit_Company_Info.fax"
                  size="small"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="item clearfix" v-if="PJID === 'YMP'">
          <div class="itemInner clearfix left">
            <div class="key">企业官网：</div>
            <div class="value">
              <p v-if="isExamine">{{ company_Info.website | textFormat }}</p>
              <el-form-item v-else>
                <el-input
                  v-model="edit_Company_Info.website"
                  size="small"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="item clearfix">
          <div class="key">企业地址：</div>
          <div class="value" prop="address_zh">
            <p v-if="isExamine">{{ company_Info.address_zh | textFormat }}</p>
            <el-form-item v-else>
              <el-input
                :disabled="!hasSavePower"
                v-model="edit_Company_Info.address_zh"
                size="small"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="item clearfix noHidden">
          <div class="key">企业简介：</div>
          <div class="value">
            <div v-show="isExamine" class="breakAll">
              <div
                v-if="edit_Company_Info.profile_zh"
                v-html="edit_Company_Info.profile_zh"
              ></div>
              <div v-else>-</div>
            </div>
            <el-form-item
              ><div v-show="!isExamine">
                <!-- <umEditor
                  ref="umEditor"
                  :styleConfig="{ width: '606px', height: '170px' }"
                ></umEditor>-->
                <el-input
                  :disabled="!hasSavePower"
                  type="textarea"
                  style="height: 170px"
                  :maxlength="maxlength"
                  show-word-limit
                  v-model="edit_Company_Info.profile_zh"
                ></el-input></div
            ></el-form-item>
          </div>
        </div>
        <div class="footer-btn">
          <el-button
            v-if="inspectButton('cos:coi:mdi')"
            type="primary"
            size="small"
            style="padding: 9px 25px"
            :disabled="
              edit_Company_Info.profile_zh &&
              edit_Company_Info.profile_zh.length > 600
            "
            @click="confirm"
            :loading="saveLoading"
            >保存</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="fl contentLine"></div>
    <div class="fl contentRight">
      <div class="clearfix avatarInfo">
        <span class="key">企业logo</span>
        <span class="value">
          <div class="name_input">
            <div class="imgDiv" v-if="inspectButton('cos:coi:mdi')">
              <div class="markImg tc">
                <fileUpload
                  @uploadCompleteOSS="uploadimg"
                  :needCropImg="fileUploadOptions.needCropImg"
                  :aspectRatio="fileUploadOptions.aspectRatio"
                  :accept="fileUploadOptions.accept"
                  :company_id="company_Info.id"
                >
                  <span>修改logo</span>
                </fileUpload>
              </div>
              <el-image
                class="avatar"
                fit="fit"
                :src="
                  edit_Company_Info.logo ? edit_Company_Info.logo : DEFAULT_LOGO
                "
              ></el-image>
            </div>
            <div class="imgDiv" v-else>
              <el-image
                class="avatar"
                fit="fit"
                :preview-src-list="srcList"
                :src="
                  edit_Company_Info.logo ? edit_Company_Info.logo : DEFAULT_LOGO
                "
              ></el-image>
            </div>
          </div>
        </span>
      </div>
      <certificate ref="certificate"  @uploadCert="uploadCertImg"></certificate>
    </div>
  </div>
</template>
<script>
import CountrySelect from "~/baseComponents/CountrySelect";
import CityComplete from "~/baseComponents/CityComplete";
import fileUpload from "~/baseComponents/fileUpload";
import certificate from "~bac/components/company/basic/certificate";
import { areaCodeList } from "~/baseUtils/AddressConfig";
import umEditor from "@/baseComponents/umEditor";
import { staffsizeList } from "~scr/utils/enumValue"; //企业规模
export default {
  components: {
    fileUpload,
    CountrySelect,
    CityComplete,
    umEditor,
    certificate,
  },
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    locale: {
      type: String,
      default: "",
    },
  },
  watch: {
    info() {
      this.company_Info = this.info;
      this.edit_Company_Info = Object.assign(this.edit_Company_Info, this.info);
      this.$refs.certificate.getCertificateStore();
    },
  },
  data() {
    return {
      fileUploadOptions: {
        needCropImg: true,
        aspectRatio: 20 / 20,
        accept: ".jpg,.jpeg,.bmp,.png",
      },
      srcList: [],
      maxlength: 600,
      areaCodeList,
      staffsizeList,
      areaCodeListCopy: [].concat(areaCodeList),
      isExamine: false,
      edit_Company_Info: {
        area_code: "+86",
      },
      company_Info: {},
      uploadCert: {},
      saveLoading: false,
      pickerOptions: {
        disabledDate: (time) => {
          return (
            parseInt(new Date(time).getFullYear()) > new Date().getFullYear()
          );
        },
      },
      avatarImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/default-logo.jpg",
    };
  },
  methods: {
    async getList() {
      let bindStatus = this.USER_INFO.company_bind_status;
      if (bindStatus == 2) {
        let companyInfo = await this.$store.dispatch(
          "baseConsole/company_getCompanyInfo",
          {
            company_id: this.USER_INFO.company_id,
            is_self: true,
          }
        );
        if (companyInfo.success) {
          this.edit_Company_Info = companyInfo.data;
          if (this.edit_Company_Info.found_year) {
            this.edit_Company_Info.found_year =
              this.edit_Company_Info.found_year + "";
          }
          if (this.edit_Company_Info.profile_zh) {
            this.edit_Company_Info.profile_zh = this.edit_Company_Info.profile_zh
              .replace(/<[^>]+>/g, "")
              .replace(/&nbsp;/gi, "");
          }
          if (this.edit_Company_Info.logo) {
            this.srcList.push(this.edit_Company_Info.logo);
          } else {
            this.srcList.push(this.DEFAULT_LOGO);
          }
        }
      }
    },
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    uploadimg(result) {
      // this.edit_Company_Info.logo = result.url;
      this.edit_Company_Info = {
        logo: result.url,
      };
    },
    uploadCertImg(url, data) {
      this.uploadCert.url = url;
      this.uploadCert.certificate_id = data.length > 0 && data[0].id;
    },
    async confirm() {
      this.saveLoading = true;
      // this.edit_Company_Info.profile_zh = this.$refs.umEditor.getContent();
      let params = Object.assign({}, this.edit_Company_Info);
      delete params.id;
      delete params.created_time;
      delete params.updated_time;
      delete params.is_show;
      delete params.city;
      delete params.status;
      delete params.industry;
      delete params.region_en;
      delete params.reason;
      // params._NOCLEAR = true;
      params.company_id = this.USER_INFO.company_id;
      if (params.found_year)
        params.found_year = this.$moment(params.found_year).format("YYYY");
      // this.$log({
      //   type: 1,
      //   topic: "console.company",
      //   id: "console.company.basic.modify",
      //   content: JSON.stringify(params),
      // });
      let uploadParams = {
        company_id: this.USER_INFO.company_id,
        url: this.uploadCert.url,
        certificate_type: 0,
      };
      uploadParams.certificate_id = this.uploadCert.id;
     
        try {
          let result = await Promise.all([
            this.$store.dispatch("baseConsole/company_saveCompanyInfo", params),
            this.uploadCert.url?this.$store.dispatch(
              "baseConsole/company_uploadQualification",
              uploadParams
            ):'',
          ]);
          if (result[0].success) {
            let _params = Object.assign({}, this.edit_Company_Info);
            _params.found_year = this.$moment(_params.found_year).format(
              "YYYY"
            );
            this.company_Info = _params;
            this.$ssTip();
            let data = await this.$store.dispatch(
              "baseStore/baseSign_getUserInfo",
              {
                user_id: this.USER_INFO.id,
              }
            );
            if (data.success) {
            }
            // this.isExamine = true;
          } else {
            this.$seTip();
          }
          if (result[1].success) {
            // this.$refs.certificate.getCompanyAptitude();
          }
          this.saveLoading = false;
        } catch (e) {
          this.saveLoading = false;
          this.$seTip();
        }
      
    },
  },
  // computed: {
  //   company_Info() {
  //     try {
  //       return Object.assign({}, _companyInfo);
  //     } catch (e) {}
  //   },
  // },
  computed: {
    hasSavePower: function () {
      return this.inspectButton("cos:coi:mdi");
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.explain {
  padding: 10px 12px;
  line-height: 18px;
  margin: 12px 0 24px;
  border-radius: 0px;
  color: #555;
  background-color: #f9f9f9;
  border-top: 2px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.content_info {
  // margin: 0 auto;
  width: 1070px;
  .contentLeft {
    padding: 10px 0 5px 12px;
    width: 750px;
    .item {
      .itemInner {
        float: left;
        // .value {
        //   width: 400px;
        // }
        &.left {
          width: 50%;
        }
        &.right {
          width: 50%;
          //   .key {
          //     width: 80px;
          //   }
        }
      }
    }
    .key {
      width: 120px;
      float: left;
      color: #808080;
      text-align: right;
      padding-right: 10px;
      line-height: 35px;
    }
  }
  .contentLine {
    height: 300px;
    width: 1px;
    background: #dcdfe6;
    margin: 120px 30px 0;
  }
  .contentRight {
    width: 250px;
    min-height: 700px;
    .avatarInfo {
      margin-bottom: 50px;
      margin-top: 20px;
    }
    .imgDiv {
      position: relative;
      width: 240px;
      height: 240px;
      margin-top: 16px;
      background: #ececec;
      .markImg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        line-height: 240px;
        transition: 0.5s;
        span {
          color: #fff;
          display: none;
        }
      }
      .avatar {
        width: 100%;
      }
      &:hover {
        .markImg {
          background-color: rgba(0, 0, 0, 0.5);
          span {
            display: block;
          }
        }
      }
    }
    .key {
      color: #808080;
    }
  }
  .footer-btn {
    padding: 10px 0 20px 120px;
  }
  // /deep/.el-select {
  //   width: 100%;
  // }
}
/deep/ .editor_container  {
  padding-top: 10px;
  .edui-btn-toolbar {
    height: 0px;
  }
  .edui-toolbar  {
    display: none;
  }
   .edui-editor-body  {
    padding-top: 2px;
  }
   p  {
    line-height: 20px;
    margin: 0px;
    color: #666;
  }
  .edui-body-container  {
    width: 606px !important;
    height: 160px !important;
    overflow-x: hidden !important;
    padding: 0 5px;
    text-align: justify;
  }
  .edui-container  {
    box-shadow: none;
    border: 1px solid #dcdfe6;
  }
}
/deep/.el-textarea__inner {
  height: 170px;
}
</style>