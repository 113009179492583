<template>
  <div class="basic">
    <div class="content clearfix">
      <!--      目前状态分为未绑定：0 ，已绑定： 2-->
      <!--      1和3是待审核和审核拒绝 已加上-->
      <div v-if="bindStatus == 2" class="tabBlock">
        <div
          v-if="BACCONFIG.basicInfoLocale == 'zh'"
          :class="{ marginT: BACCONFIG.basicInfoLocale != 'all' }"
        >
          <infoZh
            @changeCompany="companyStats"
            :info="info"
            :locale="activeName"
          ></infoZh>
        </div>
        <div
          v-else-if="BACCONFIG.basicInfoLocale == 'en'"
          :class="{ marginT: BACCONFIG.basicInfoLocale != 'all' }"
        >
          <infoEn
            @changeCompany="companyStats"
            :info="info"
            :locale="activeName"
          ></infoEn>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick" v-else>
          <el-tab-pane
            :label="$t('englishInfo')"
            name="en"
            v-if="currentLocale == 'en'"
          >
            <infoEn
              @changeCompany="companyStats"
              :info="info"
              :locale="activeName"
            ></infoEn>
          </el-tab-pane>
          <el-tab-pane :label="$t('chineseInfo')" name="zh">
            <infoZh
              @changeCompany="companyStats"
              :info="info"
              :locale="activeName"
            ></infoZh>
          </el-tab-pane>
          <el-tab-pane
            :label="$t('englishInfo')"
            name="en"
            v-if="currentLocale == 'zh'"
          >
            <infoEn
              @changeCompany="companyStats"
              :info="info"
              :locale="activeName"
            ></infoEn>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="bindStatus == 0">
        <yetJoin @changeCompany="companyStats"></yetJoin>
      </div>
      <div class="applyFor" v-if="bindStatus == 1 || bindStatus == 3">
        <applyFor @changeCompany="companyStats"></applyFor>
      </div>
    </div>
    <!-- <register ref="registerCompany" @confirmJoin="confirmJoin"></register> -->
  </div>
</template>

<script>
import infoZh from "~bac/components/company/basic/infoZh";
import infoEn from "~bac/components/company/basic/infoEn";
import applyFor from "~bac/components/company/basic/applyFor";
import yetJoin from "~bac/components/company/basic/yetJoin";
import register from "~/baseComponents/registerCompany";
export default {
  components: {
    infoZh,
    infoEn,
    applyFor,
    yetJoin,
    register,
  },
  data() {
    return { bindStatus: null, currentLocale: "", info: {}, activeName: "" };
  },
  methods: {
    confirmJoin(val) {
      console.log(val);
    },
    companyStats(status) {
      this.bindStatus = status;
    },
    handleClick() {
      if (
        JSON.stringify(this.$store.state.baseConsole.selfCompanyInfo) != "{}"
      ) {
        let _companyInfo = Object.assign(
          {},
          this.$store.state.baseConsole.selfCompanyInfo
        );
        _companyInfo.found_year =
          _companyInfo.found_year == 0 ? "" : _companyInfo.found_year + "";
        this.info = _companyInfo;
      }
    },
  },
  mounted() {
    this.currentLocale = this.LOCALE;
    this.activeName = this.LOCALE;
    this.bindStatus = this.USER_INFO.company_bind_status;
  },
};
</script>

<style lang="less" scoped>
.basic {
  .content {
    // padding: 0 12px;
  }
  .marginT {
    margin-top: 10px;
  }
  .applyFor {
    padding: 30px 12px;
  }
}
</style>