<template>
  <div class="mc_sub_page" v-loading="loading">
    <div class="isUpload">
      <p>
        <span class="atTopPosition">
          <span class="textInfo" v-if="locale == 'en'">
            Bussiness License
          </span>
          <span class="textInfo" v-else>企业证件</span>
        </span>
      </p>
      <div>
        <div class="imgDiv">
          <div class="markImg tc" v-if="inspectButton('coi:coc:upc')">
            <fileUpload
              @uploadCompleteOSS="uploadComplete"
              :accept="fileUploadOptions.accept"
              :autoUpload="fileUploadOptions.autoUpload"
              ref="uploadComponents"
              :addwaterMark="true"
            >
              <span v-if="locale == 'en'">Edit</span>
              <span v-else>修改企业证件</span>
            </fileUpload>
          </div>
          <div class="certStatus">
            <div v-if="imgData.length > 0">
              <el-tag
                type="info"
                effect="plain"
                size="small"
                v-if="imgData[0].audit_status == 0"
                ><span v-if="locale == 'en'">being audited</span>
                <span v-else>待认证</span>
              </el-tag>
              <el-tag
                type="success"
                effect="plain"
                size="small"
                v-if="imgData[0].audit_status == 1"
              >
                <span v-if="locale == 'en'"> approved</span>
                <span v-else>已认证</span></el-tag
              >
              <el-tag
                type="warning"
                effect="plain"
                size="small"
                v-if="imgData[0].audit_status == 2"
              >
                <span v-if="locale == 'en'"> audit failed</span>
                <span v-else>认证失败</span>
              </el-tag>
            </div>
          </div>
          <div>
            <el-image
              v-if="businessUrl"
              class="avatar"
              :src="businessUrl"
              fit="scale-down"
            ></el-image>
            <el-image
              v-else
              class="avatar"
              :src="imgData.length > 0 ? imgData[0].url : defaultImg"
              fit="scale-down"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fileUpload from "~/baseComponents/fileUpload";
export default {
  props: {
    locale: {
      type: String,
      default: "zh",
    },
  },
  data() {
    return {
      defaultImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/default-certificate.png",
      businessUrl: "",
      businessName: "",
      upload: true,
      fileUploadOptions: {
        accept: ".jpg,.jpeg,.bmp,.png",
        autoUpload: true,
      },
      btnLoading: false,
      isShow: 0,
      imgData: [],
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    let imgEn =
      "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/default-certificateEn.png";
    let imgZh =
      "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/default-certificate.png";
    if (this.LOCALE == "en") {
      this.defaultImg = imgEn;
    } else {
      this.defaultImg = imgZh;
    }
  },
  methods: {
    async getList() {
      this.loading = true;
      try {
        let params = {};
        params.company_id = this.USER_INFO.company_id;
        params.certificate_type = 0;
         let data = await this.$store.dispatch(
            "baseConsole/company_getCertificateList",
            params
          );
          if (data.success) {
            this.loading = false;
            this.imgData = data.data;
            if (this.imgData.length) {
              this.upload = false;
            }
          }
      } catch (error) {
        this.loading = false;
      }
    },
    uploadComplete(result) {
      this.businessUrl = result.url;
      this.businessName = result.name;
      this.$emit("uploadCert", result.url, this.imgData);
      //   this.$log({
      //     type: 1,
      //     topic: "console.company",
      //     id: "console.company.license.upload",
      //     content: `${result.url}`,
      //   });
    },
    //获取企业资质信息
    async getCompanyAptitude() {
      let params = {};
      params.company_id = this.USER_INFO.company_id;
      params.certificate_type = 0;
      let result = await this.$store.dispatch(
        "baseConsole/company_getCertificateList",
        params
      );
      if (result.success) {
        this.imgData = result.data;
      }
      //console.log(result);
      if (this.imgData.length) {
        this.upload = false;
      }
    },
    getCertificateStore() {
      if (this.$store.state.baseConsole.selfCertificate.length > 0) {
        this.imgData = this.$store.state.baseConsole.selfCertificate;
      }
    },
    //上传证书提交
    async submitBtn() {
      if (!this.businessUrl) {
        return false;
      }
      this.btnLoading = true;
      let params = {};
      params.company_id = this.USER_INFO.company_id;
      params.url = this.businessUrl;
      params.certificate_type = 0;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_uploadQualification",
          params
        );
        if (result.success) {
          this.$message({
            message: this.$t("submitSuccess"),
            type: "success",
          });
        }
        this.getCompanyAptitude();
        this.btnLoading = false;
      } catch (error) {
        this.btnLoading = false;
      }
    },
    //修改资质
    async editQualification() {
      this.btnLoading = true;
      let params = {};
      params.company_id = this.USER_INFO.company_id;
      params.url = this.businessUrl;
      params.certificate_type = 0;
      params.certificate_id = this.imgData[0].id;
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_uploadQualification",
          params
        );
        if (result.success) {
          this.$message({
            message: this.$t("submitSuccess"),
            type: "success",
          });
        }
        this.getCompanyAptitude();
        this.btnLoading = false;
      } catch (error) {
        this.btnLoading = false;
      }
    },

    //点击修改按钮
    switchBtn() {
      this.isShow = 1;
    },
    //取消按钮
    cancelBtn() {
      this.isShow = 0;
      this.$refs.uploadComponents.deleteOssItem(this.businessName);
      this.businessUrl = this.imgData.url;
      this.businessName = "";
    },
    //保存
    saveBtn() {
      this.editQualification();
      this.isShow = 0;
    },
  },
  components: { fileUpload },
};
</script>

<style scoped lang="less">
.mc_sub_page {
  position: relative;
}
.modifyBtn {
  position: absolute;
  bottom: 0px;
  right: 15px;
}
.page_title {
  //font-weight: 600;
  font-size: 16px;
  &.sub {
    font-size: 14px;
  }
}
.text_item {
  margin-top: 16px;
  margin-bottom: 18px;
  p {
    padding-bottom: 12px;
    font-size: 14px;
    a {
      color: #0083f6;
    }
  }
}
.upload_tip {
  margin-top: 16px;
  margin-bottom: 30px;
}
.uploadBox {
  width: 240px;
  height: 167px;
  margin-bottom: 15px;
  cursor: pointer;
}
.checkTip {
  margin-top: 20px;
}
.uploadBtn {
  //   &.en {
  //     margin-left: 158px;
  //   }
  //   &.zh {
  //     margin-left: 73px;
  //   }
}
.isUpload {
  font-size: 14px;
  .sub {
    margin-top: 30px;
    margin-bottom: 16px;
    color: #808080;
  }
  p {
    position: relative;
    margin-bottom: 12px;
    &.flex {
      display: flex;
    }
    .textInfo {
      margin-right: 8px;
      color: #808080;
    }
    .el-image {
      width: 240px;
      height: 167px;
    }
  }
}
.imgDiv {
  position: relative;
  width: 240px;
  height: 180px;
  margin-top: 16px;
  background: #ececec;
  .markImg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    line-height: 180px;
    transition: 0.5s;
    span {
      color: #fff;
      display: none;
    }
  }
  &:hover {
    .markImg {
      background-color: rgba(0, 0, 0, 0.5);
      span {
        display: block;
      }
    }
  }
}
.certStatus {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 3;
}
.avatar {
  width: 100%;
  height: 180px;
}
.cretImage {
  width: 240px;
  height: 167px;
  margin-top: 10px;
}
.isuploadTip {
  margin: 15px 0 30px;
}
.atTopPosition {
  margin-bottom: 10px;
}
.blueColor {
  color: #0083f6;
}
</style>
